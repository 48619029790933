
// React and Hooks
import React, { useState, useEffect } from 'react';

// Icon Imports
import {
  FaCompress,
  FaPencilAlt,
  FaUpload,
  FaTools,
  FaTrash,
  FaSpinner,
  FaLightbulb, 
  FaSoundcloud,// Prompts icon
} from 'react-icons/fa';

// CSS Import
import './ContentToolbar.css';

// Service and Component Imports
import {
  getSubDataForProject,
  uploadFile,
  addSubDataToProject,
  deleteFileFromStorageAndFirestore,
} from '../../services/projectService';
import DrawCanvas from './DrawCanvas';
import prompts from './prompts';
// Add this import statement
import VoiceTranscription from './VoiceTranscription'; // Adjust the path if necessary
import { extractFileNameFromUrl } from '../../services/Utilities';

function ContentToolbar({ onMinimize, currentProjectId, setIsVoiceTabActive, canopy2Active}) {
  /* ============================================================
     State Variables
  ============================================================ */
  const [isMinimized, setIsMinimized] = useState(true);
  const [activeIcon, setActiveIcon] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]); // Tracks files being uploaded

  /* ============================================================
     Effect Hooks
  ============================================================ */

  // Adjust main content padding based on toolbar state
  useEffect(() => {
    const mainContent = document.querySelector('.main-content');
    if (mainContent) {
      if (isMinimized) {
        mainContent.style.paddingRight = '100px'; // Toolbar minimized
      } else if (activeIcon === 'draw') {
        mainContent.style.paddingRight = '50%'; // Expand more for Draw mode
      } else {
        mainContent.style.paddingRight = '30%'; // Regular expanded toolbar
      }
    }
  }, [isMinimized, activeIcon]);

  // Fetch uploaded files when currentProjectId changes
  useEffect(() => {
    if (currentProjectId) {
      getSubDataForProject(currentProjectId).then((subData) => {
        if (subData && subData.files) {
          setUploadedFiles(subData.files);
        } else {
          setUploadedFiles([]); // Clear files if no data is found
        }
      });
    }
  }, [currentProjectId]);

  /* ============================================================
     Event Handlers
  ============================================================ */

  // Handle prompt button click
  const handlePromptClick = (prompt) => {
    console.log(`Prompt clicked: ${prompt}`);

    // Find the iframe and send the message with the prompt
    const iframe = document.getElementById('voiceflow-iframe');
    if (iframe) {
      iframe.contentWindow.postMessage(
        {
          type: 'requestIntent',
          request: prompt,
        },
        '*' // Use '*' for cross-origin messaging
      );
    }
  };

  // Handle file upload
  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    const uploadingFileNames = files.map((file) => file.name);
    setUploadingFiles((prev) => [...prev, ...uploadingFileNames]);

    try {
      const newFileUploadPromises = files
        .filter((file) => typeof file === 'object')
        .map((file) => uploadFile(file, currentProjectId));

      const newFileURLs = await Promise.all(newFileUploadPromises);

      const allFileURLs = [...uploadedFiles, ...newFileURLs];

      const updatedSubDataWithFiles = {
        files: allFileURLs,
      };

      await addSubDataToProject(currentProjectId, updatedSubDataWithFiles);

      setUploadedFiles(allFileURLs);
      setUploadingFiles((prev) =>
        prev.filter((name) => !uploadingFileNames.includes(name))
      );
    } catch (error) {
      console.error('Error during file upload or Firestore update:', error);
      setUploadingFiles((prev) =>
        prev.filter((name) => !uploadingFileNames.includes(name))
      );
    }
  };

  // Handle file deletion
  const handleDeleteFile = async (fileToDelete) => {
    try {
      await deleteFileFromStorageAndFirestore(currentProjectId, fileToDelete);
      const updatedFiles = uploadedFiles.filter((file) => file !== fileToDelete);
      setUploadedFiles(updatedFiles);

      const updatedSubDataWithFiles = {
        files: updatedFiles,
      };

      await addSubDataToProject(currentProjectId, updatedSubDataWithFiles);
      console.log('File deleted successfully');
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  // Minimize the toolbar
  const handleMinimizeClick = () => {
    setIsMinimized(true);
    setActiveIcon(null);
    setIsVoiceTabActive(false); // Reset the voice tab active state
  };

  // Handle icon click to expand toolbar
  const handleIconClick = (icon) => {
    setActiveIcon(icon);
    setIsMinimized(false);
    onMinimize(false);
  
    // Notify parent about the active state of the voice tab
    if (icon === 'voice') {
      setIsVoiceTabActive(true);
    } else {
      setIsVoiceTabActive(false);
    }
  };
  

  /* ============================================================
     Render Functions
  ============================================================ */

  // Render content based on the active icon
  const renderActiveContent = () => {
    switch (activeIcon) {
      case 'draw':
        return (
          <div className="feature-content">
            <h3>Draw Tool</h3>
            <DrawCanvas />
          </div>
        );

      case 'upload':
        return (
          <div className="upload-file-container">
            <h3 className="upload-file-header">Upload and attach files</h3>
            <label htmlFor="file-upload" className="upload-file-input">
              Click to upload or drag and drop
              <input
                id="file-upload"
                type="file"
                multiple
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
            </label>
            <h4>
              Uploaded Files{' '}
              {uploadingFiles.length > 0 && (
                <FaSpinner className="loading-icon" />
              )}
            </h4>
            <ul className="file-list">
              {uploadedFiles.length > 0 ? (
                uploadedFiles.map((file, index) => {
                    const fileName = extractFileNameFromUrl(file);

                  return (
                    <li key={index} className="file-list-item">
                      <div className="file-info">
                        <span className="file-icon">📄</span>
                        <a
                          href={file}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="file-name"
                        >
                          {fileName}
                        </a>
                        {uploadingFiles.includes(fileName) ? (
                          <FaSpinner className="loading-icon" />
                        ) : (
                          <FaTrash
                            className="delete-icon"
                            onClick={() => handleDeleteFile(file)}
                          />
                        )}
                      </div>
                    </li>
                  );
                })
              ) : (
                <p>No files uploaded yet.</p>
              )}
            </ul>
          </div>
        );

      case 'prompts':
        return (
          <div className="prompts-content">
            <h3>Class Starters</h3>
            {prompts.map((prompt, index) => (
              <button
                key={index}
                onClick={() => handlePromptClick(prompt)}
                className="prompt-button"
              >
                {prompt}
              </button>
            ))}
          </div>
        );

        case 'voice':
            return (
              <div className="feature-content">
                
                <VoiceTranscription onSendMessage={handlePromptClick} /> {/* Pass as prop */}
              </div>
            );
      

      default:
        return null;
    }
  };

  /* ============================================================
     Component Render
  ============================================================ */

  // Determine toolbar class based on state
  const toolbarClass = `content-toolbar ${isMinimized ? 'minimized' : ''} ${
    activeIcon === 'draw' ? 'expanded-draw' : ''
  }`;

  return (
    <div className={toolbarClass}>
      {/* Toolbar Header */}
      <div className="toolbar-header">
        {isMinimized ? (
          <FaTools size={20} className="title-icon" />
        ) : activeIcon === 'draw' ? (
          <FaPencilAlt size={25} className="title-icon" />
        ) : activeIcon === 'upload' ? (
          <FaUpload size={25} className="title-icon" />
        ) : activeIcon === 'prompts' ? (
          <FaLightbulb size={25} className="title-icon" />
        ) : activeIcon === 'voice' ? (
          <FaSoundcloud size={25} className="title-icon" />
        ) : null}
  
        {!isMinimized && (
          <FaCompress
            size={15}
            className="toolbar-minimize-icon"
            onClick={handleMinimizeClick}
          />
        )}
      </div>
  
      {/* Toolbar Content */}
      <div className="toolbar-content">
        {isMinimized ? (
          <>
            {/* Draw Icon */}
            <div
              className={`toolbar-icon ${canopy2Active ? 'disabled-icon' : ''}`}
              onClick={() => !canopy2Active && handleIconClick('draw')}
            >
              <FaPencilAlt size={20} />
              <span className="tooltip-text">Draw</span>
            </div>
  
            {/* Upload Icon (always enabled) */}
            <div className="toolbar-icon" onClick={() => handleIconClick('upload')}>
              <FaUpload size={20} />
              <span className="tooltip-text">Upload</span>
            </div>
  
            {/* Prompts Icon */}
           {/*} <div
              className={`toolbar-icon ${canopy2Active ? 'disabled-icon' : ''}`}
              onClick={() => !canopy2Active && handleIconClick('prompts')}
            >
              <FaLightbulb size={20} />
              <span className="tooltip-text">Prompts</span>
            </div>*/}
  
            {/* Voice Icon */}
            <div
              className={`toolbar-icon ${canopy2Active ? 'disabled-icon' : ''}`}
              onClick={() => !canopy2Active && handleIconClick('voice')}
            >
              <FaSoundcloud size={20} />
              <span className="tooltip-text">Voice</span>
            </div>
          </>
        ) : (
          renderActiveContent()
        )}
      </div>
    </div>
  );
  
}

export default ContentToolbar;
