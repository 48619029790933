import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useState } from 'react';
import './ParentSignup.css'; // Ensure this path is correct
import { COMPANY_NAME } from '../../config';

function ParentSignup({ onClose }) {
  const [formData, setFormData] = useState({
    parentEmail: '',
    schoolName: '',
    studentFirstName: '',
    studentLastName: '',
    studentID: '',
    country: '',
    state: '',
    city: '',
    grade: '',
  });

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to clear the form
  const clearForm = () => {
    setFormData({
      parentEmail: '',
      schoolName: '',
      studentFirstName: '',
      studentLastName: '',
      studentID: '',
      country: '',
      state: '',
      city: '',
      grade: '',
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setLoading(true); // Set loading to true when submitting the form
  
    try {
      // Initialize Firebase Functions
      const functions = getFunctions();
      const createStudentAndParentAccount = httpsCallable(functions, 'createStudentAndParentAccount');
      const sendEmailCredentials = httpsCallable(functions, 'sendEmailcredentials'); // Call the sendEmailCredentials function
  
      // Prepare data to send to the cloud function for account creation
      const data = {
        parentEmail: formData.parentEmail,
        schoolName: formData.schoolName,
        studentFirstName: formData.studentFirstName,
        studentLastName: formData.studentLastName,
        studentID: formData.studentID,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        grade: formData.grade
      };
      console.log('emaildata', data);
      // Call the Cloud Function to create student and parent accounts
      const result = await createStudentAndParentAccount(data);
  
      if (result.data.success) {
        const { studentEmail, studentPassword, studentFirstName, studentLastName, studentUsername, schoolName} = result.data;
  
        // Send email with student's login credentials
        const emailData = {
          parentEmail: formData.parentEmail, // Parent's email
          studentName: `${formData.studentFirstName} ${formData.studentLastName}`, // Student's full name
          studentEmail: studentEmail, // Student's email
          studentPassword: studentPassword,
          studentUsername: studentUsername,
          schoolName: schoolName // School name
        };
  
        // Call the sendE:ailCredentials cloud function
        await sendEmailCredentials(emailData);
  
        // Show success message and clear the form
        setSuccessMessage(
          `Account created successfully! We have sent an email with your student's login credentials to ${formData.parentEmail}.`
        );
        clearForm(); // Clear the form fields
  
        // After 3 seconds, hide the success message and reset the state
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      } else {
        setError('Failed to create accounts.');
      }
    } catch (err) {
      setError(`Error creating accounts: ${err.message}`);
      console.error('Error creating accounts:', err);
    } finally {
      setLoading(false); // Set loading to false once the request is complete
    }
  };
  



  const [hoverInfo, setHoverInfo] = useState(''); // State to track hover information

  const handleHover = (info) => {
    setHoverInfo(info); // Set hover text when hovering over a field
  };
  
  return (
    <div className="parent-signup-container" onClick={onClose}>
      <div className="parent-signup-wrapper">
        <div className="parent-signup-form-section" onClick={(e) => e.stopPropagation()}>
          <h2>Student Registration</h2>
          {error && <p className="error-message">{error}</p>}
  
          <form onSubmit={handleSubmit}>
            <h3>Parent or Student Email</h3>
            <div
              className="input-group"
              onMouseEnter={() => setHoverInfo('Enter the email address you would like to associate with the student account')}
              onMouseLeave={() => setHoverInfo('')}
            >
              <input
                type="email"
                name="parentEmail"
                placeholder="Parent or Student Email"
                value={formData.parentEmail}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </div>
  
            <h3>Student Information</h3>
  
            <div
              className="input-group"
              onMouseEnter={() => setHoverInfo('Enter the name of the school the student attends.')}
              onMouseLeave={() => setHoverInfo('')}
            >
              <input
                type="text"
                name="schoolName"
                placeholder="School Name"
                value={formData.schoolName}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </div>
  
            <div
              className="input-group"
              onMouseEnter={() => setHoverInfo('Enter the first name of the student.')}
              onMouseLeave={() => setHoverInfo('')}
            >
              <input
                type="text"
                name="studentFirstName"
                placeholder="Student First Name"
                value={formData.studentFirstName}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </div>
  
            <div
              className="input-group"
              onMouseEnter={() => setHoverInfo('Enter the last name of the student.')}
              onMouseLeave={() => setHoverInfo('')}
            >
              <input
                type="text"
                name="studentLastName"
                placeholder="Student Last Name"
                value={formData.studentLastName}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </div>
  
            <div
              className="input-group"
              onMouseEnter={() => setHoverInfo('Enter the student ID. If unknown, type N/A.')}
              onMouseLeave={() => setHoverInfo('')}
            >
              <input
                type="text"
                name="studentID"
                placeholder="Student ID"
                value={formData.studentID}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
  
            <div
              className="input-group"
              onMouseEnter={() => setHoverInfo('Enter the student\'s grade.')}
              onMouseLeave={() => setHoverInfo('')}
            >
              <input
                type="text"
                name="grade"
                placeholder="Grade"
                value={formData.grade}
                onChange={handleChange}
                required
                disabled={loading}
              />
            </div>
  
            <div
              className="input-group"
              onMouseEnter={() => setHoverInfo('Enter the country of residence (optional).')}
              onMouseLeave={() => setHoverInfo('')}
            >
              <input
                type="text"
                name="country"
                placeholder="Country"
                value={formData.country}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
  
            <div
              className="input-group"
              onMouseEnter={() => setHoverInfo('Enter the state of residence (optional).')}
              onMouseLeave={() => setHoverInfo('')}
            >
              <input
                type="text"
                name="state"
                placeholder="State"
                value={formData.state}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
  
            <div
              className="input-group"
              onMouseEnter={() => setHoverInfo('Enter the city of residence (optional).')}
              onMouseLeave={() => setHoverInfo('')}
            >
              <input
                type="text"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
  
            <button type="submit" className="signup-button" disabled={loading}>
              {loading ? 'Creating Account...' : 'Create Account'}
            </button>
            <button type="button" className="close-button" onClick={onClose} disabled={loading}>
              Back
            </button>
          </form>
        </div>
  
        {/* Right section with dynamic hover info */}
        <div className="parent-signup-info-section">
          {successMessage ? (
            <p className="success-message">{successMessage}</p>
          ) : (
            <>
              <img src="https://canopy.school/static/media/logo.f662b53f201cbdb4b1b4.png" alt="Signup info" />
              <p className="signup-info-text">
                {hoverInfo || `Register your Child with ${COMPANY_NAME} Today!`}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
  
  
}

export default ParentSignup;
