// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDZ9HBWLVFIYqeI0A7E87KnorJcKX0Gvbo",
  authDomain: "naxosdigitals.firebaseapp.com",
  projectId: "naxosdigitals",
  storageBucket: "naxosdigitals.firebasestorage.app",
  messagingSenderId: "702748413769",
  appId: "1:702748413769:web:92744d5b6f3d750df8d498",
  measurementId: "G-W08F8GB5B3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app)
export const storage = getStorage(app); 


export { db, auth, };
